<template>
  <main class="lg:relative">
    <div
      class="w-full pt-16 pb-20 mx-auto text-center max-w-7xl lg:py-48 lg:text-left"
    >
      <div class="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
        <h1
          class="text-4xl font-extrabold tracking-tight text-secondary sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl"
        >
          <span class="block xl:inline">Grow Your Wealth</span>
          {{ ' ' }}
          <span class="block text-primary xl:inline">With Us</span>
        </h1>
        <p
          class="max-w-md mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl"
        >
          We are here to help you on various platforms like finance section,
          legal sector, digital marketing sector. We are the one stop solution
          for any of your demand.
        </p>
        <div
          class="mt-10 sm:flex sm:justify-center lg:justify-start"
          v-if="!loggedInUser.id"
        >
          <div class="rounded-md shadow">
            <a
              @click="join()"
              class="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white border border-transparent rounded-md bg-primary hover:bg-darkPrimary md:py-4 md:text-lg md:px-10"
            >
              Become a Member
            </a>
          </div>
          <!-- <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
            <a
              href="#"
              class="flex items-center justify-center w-full px-8 py-3 text-base font-medium bg-white border border-transparent rounded-md text-primary hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
            >
              Live demo
            </a>
          </div> -->
        </div>
      </div>
    </div>
    <div
      class="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full"
    >
      <img
        class="absolute inset-0 object-cover w-full h-full"
        src="../assets/note-get-grow.jpg"
        alt=""
      />
    </div>
  </main>
  <financial-section></financial-section>
  <legal-section></legal-section>
  <digital-marketing-section></digital-marketing-section>
  <real-estate-section></real-estate-section>
  <!-- <booking-section></booking-section> -->
  <modal @close="openModal = false" :visibility="openModal">
    <template v-slot:content>
      <auth @cancle="openModal = false" />
    </template>
  </modal>
</template>
<script>
import FinancialSection from './FinancialSection.vue';
import LegalSection from './LegalSection.vue';
import DigitalMarketingSection from './DigitalMarketingSection.vue';
import RealEstateSection from './RealEstateSection.vue';
// import BookingSection from './BookingSection.vue';
import { mapState } from 'vuex';
import Modal from '../components/Modal.vue';
import Auth from './Auth.vue';

export default {
  components: {
    FinancialSection,
    LegalSection,
    DigitalMarketingSection,
    RealEstateSection,
    Modal,
    // BookingSection,
    Auth,
  },
  data() {
    return {
      openModal: false,
    };
  },
  computed: {
    ...mapState('auth', ['loggedInUser']),
  },
  methods: {
    join() {
      this.openModal = true;
    },
  },
};
</script>

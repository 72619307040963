import Orders from '../layout/admin-pages/Orders.vue';
import Users from '../layout/admin-pages/Users.vue';
import Dashboard from '../layout/admin-pages/Dashboard.vue';

const adminRoutes = [
  {
    path: 'dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: 'orders',
    name: 'Orders',
    component: Orders,
  },
  {
    path: 'users',
    name: 'Users',
    component: Users,
  },
];

export default adminRoutes;

<template>
  <div class="inset-0 z-50 overflow-y-auto ">
    <div class="text-center">
      <div class="inset-0 w-full h-full bg-gray-900 bg-opacity-50 "></div>
      <!-- <span class="inline-block h-screen align-middle">&ZeroWidthSpace;</span> -->
      <div
        class="relative max-w-full align-middle transition-all min-w-content text-start"
      >
        <button
          aria-label="Close panel"
          class="inline-block outline-none focus:outline-none absolute end-4 top-4 z-[60] right-4"
          @click="closeModal()"
        >
          <span class="sr-only">close</span
          ><svg
            class="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          class="py-6 px-5 sm:p-8 bg-light md:max-w-[480px] min-h-screen md:min-h-0 h-full md:h-auto flex flex-col justify-center md:rounded-xl"
        >
          <div class="flex justify-center">
            <a class="inline-flex">
              <span class="relative w-32 h-24 overflow-hidden md:w-40">
                <div
                  class="box-border absolute inset-0 block m-0 overflow-hidden"
                >
                  <img
                    alt="Get Grow Wealth"
                    sizes="100vw"
                    src="../assets/logo.png"
                  />
                  <!-- class="box-border absolute inset-0 block object-contain w-0 h-0 max-w-full max-h-full min-w-full min-h-full p-0 m-auto border-none" -->
                </div>
              </span>
            </a>
          </div>
          <login
            v-if="!showRegistrationModal"
            @showRegister="showRegistrationModal = true"
            @close="closeModal()"
          ></login>
          <register
            @showRegister="showRegistrationModal = false"
            v-if="showRegistrationModal"
            @close="closeModal()"
          ></register>
          <!-- <app-forgot-password
              *ngSwitchCase="coreService?.localConstant?.forgotPassword"
            ></app-forgot-password> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Login from './Login.vue';
import Register from './Register.vue';
export default {
  components: {
    Login,
    Register,
  },
  data: () => {
    return {
      showRegistrationModal: false,
    };
  },

  methods: {
    closeModal() {
      this.$emit('cancle');
    },
  },
};
</script>

const mutations = {
  setNotification(state, payload) {
    state.notification.title = payload.title;
    state.notification.subtitle = payload.subtitle;
    state.notification.type = payload.type;
    state.notification.isVisible = true;
  },
  resetState(state) {
    state.notification.title = '';
    state.notification.subtitle = '';
    state.notification.type = '';
    state.notification.isVisible = false;
  },
};

export default mutations;

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div id="legal-section" class="bg-faintPrimary">
    <div
      class="max-w-4xl px-4 py-16 mx-auto sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8"
    >
      <h2 class="text-3xl font-extrabold tracking-tight text-white">
        Legal Services That All You Need
      </h2>
      <p class="max-w-3xl mt-4 text-lg text-gray-50">
        Get Legal Advice And Various Legal Services At Very Reasonable Price.
      </p>
      <div
        class="grid grid-cols-1 mt-12 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16"
      >
        <div v-for="feature in features" :key="feature.name">
          <div>
            <span
              class="flex items-center justify-center w-12 h-12 bg-white rounded-md bg-opacity-20"
            >
              <component
                :is="feature.icon"
                class="w-6 h-6 text-white"
                aria-hidden="true"
              />
            </span>
          </div>
          <div class="mt-6">
            <h3 class="text-lg font-medium text-white">{{ feature.name }}</h3>
            <p class="mt-2 text-base text-gray-50">
              {{ feature.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CalculatorIcon,
  ReceiptTaxIcon,
  DocumentAddIcon,
  CollectionIcon,
} from '@heroicons/vue/outline';

const features = [
  {
    name: 'Accounting',
    href: '#',
    description:
      'Book Keeping, Auditing, Tax Accounting, Management and governmental Accounting etc',
    icon: CalculatorIcon,
  },
  {
    name: 'Tax',
    href: '#',
    description:
      'PAN, TAN, TDS, TDS Return, Wealth Tax Return, Income Tax Return etc',
    icon: ReceiptTaxIcon,
  },
  {
    name: 'ROC Services',
    href: '#',
    description:
      'Incorporation of company (Public / Private / Opc), Incorporation of LLP, Incorporation of Section 8 Company, DIN eKYC, Board Resolution Preparations etc',
    icon: DocumentAddIcon,
  },
  {
    name: 'GST',
    href: '#',
    description: 'GST Consultant, GST Registration, GST Return etc',
    icon: CollectionIcon,
  },
];

export default {
  setup() {
    return {
      features,
    };
  },
};
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div id="digital-marketing" class="bg-white">
    <div
      class="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8"
    >
      <div>
        <h2
          class="text-base font-semibold tracking-wide uppercase text-primary"
        >
          Digital Marketing
        </h2>
        <p class="mt-2 text-3xl font-extrabold text-gray-900">
          All-in-one platform
        </p>
        <p class="mt-4 text-lg text-gray-500">
          The best marketing doesn’t feel like marketing.
        </p>
      </div>
      <div class="mt-12 lg:mt-0 lg:col-span-2">
        <dl
          class="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-2 sm:gap-x-6 lg:gap-x-8"
        >
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt>
              <CheckIcon
                class="absolute w-6 h-6 text-green-500"
                aria-hidden="true"
              />
              <p class="text-lg font-medium leading-6 text-gray-900 ml-9">
                {{ feature.name }}
              </p>
            </dt>
            <dd class="mt-2 text-base text-gray-500 ml-9">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from '@heroicons/vue/outline';

const features = [
  {
    name: 'Digital Marketing Services',
    description: 'Logo Design, Banner Design, Different Templates',
  },
];

export default {
  components: {
    CheckIcon,
  },
  setup() {
    return {
      features,
    };
  },
};
</script>

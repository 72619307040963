import axios from '../../../middleware/Axios';
const actions = {
  getCourses(context, payload) {
    return new Promise((resolve, reject) => {
      let url = `/api/v1/courses`;
      if (payload && payload.type) {
        url = url + `?type=${payload.type}`;
      }
      axios
        .get(url)
        .then((res) => {
          context.commit('setCourses', res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default actions;

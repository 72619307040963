import Auth from '../../../middleware/Auth';
import axios from '../../../middleware/Axios';
import camelToSnake from '../../../utils/camelToSnake';
const actions = {
  login(context, payload) {
    return new Promise((resolve, reject) => {
      const auth = new Auth();
      axios
        .post('api/v1/login', payload, camelToSnake(payload))
        .then((res) => {
          auth.setToken(res.data.accessToken);
          auth.setUser(res.data.user);
          context.commit('setLoggedInUser', res.data.user);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  register(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/register', camelToSnake(payload))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  },
  getPaymentModes(context) {
    return new Promise((resolve, reject) => {
      axios
        .get('api/v1/payment-modes')
        .then((res) => {
          resolve(res.data.data);
          context.commit('setPaymentModes', res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUser(context) {
    const loggedInUser = Auth.getUser();
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/users/${loggedInUser.id}`)
        .then((res) => {
          if (res.status === 200) {
            context.commit('setLoggedInUser', res.data.data);
            resolve(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateUser(context, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`api/v1/users/${payload.id}`, camelToSnake(payload))
        .then((res) => {
          context.commit('setLoggedInUser', res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getUserTree(_, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`api/v1/users/${userId}/nested`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default actions;

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div id="financial-section" class="relative py-16 bg-white sm:py-24 lg:py-32">
    <div
      class="max-w-md px-4 mx-auto text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl"
    >
      <h2 class="text-base font-semibold tracking-wider uppercase text-primary">
        Financial Services
      </h2>
      <p
        class="mt-2 text-3xl font-extrabold tracking-tight text-secondary sm:text-4xl"
      >
        All Your Financial Needs Are One Click Away
      </p>
      <p class="mx-auto mt-5 text-xl text-gray-500 max-w-prose">
        We are very happy to provide you various types of financial services
        which will make you very easy and secure to handle your accounting,
        insurances, trading etc.
      </p>
      <div class="mt-12">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
          <div v-for="feature in services" :key="feature.name" class="pt-6">
            <div class="flow-root px-6 pb-8 rounded-lg bg-gray-50">
              <div class="-mt-6">
                <div>
                  <span
                    class="inline-flex items-center justify-center p-3 rounded-md shadow-lg bg-darkPrimary"
                  >
                    <component
                      :is="feature.icon"
                      class="w-6 h-6 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <h3
                  class="mt-8 text-lg font-medium tracking-tight text-gray-900"
                >
                  {{ feature.name }}
                </h3>
                <p class="mt-5 text-base text-gray-500">
                  {{ feature.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TrendingUpIcon,
  DocumentTextIcon,
  // CurrencyRupeeIcon,
  UsersIcon,
  CurrencyDollarIcon,
  // LibraryIcon,
} from '@heroicons/vue/outline';

const services = [
  {
    name: 'Share Market',
    icon: TrendingUpIcon,
    description: 'Demat A/c opening, Equity Market Services',
  },
  {
    name: 'Insurance',
    icon: DocumentTextIcon,
    description: 'Best Plans for Health Insurance, Life Insurance',
  },

  {
    name: 'NPS',
    icon: UsersIcon,
    description: 'Retirement Oriented Various Investment Options',
  },
  {
    name: 'Mutual Fund',
    icon: CurrencyDollarIcon,
    description:
      'Investment in Securities like stocks, commodities and even real estate',
  },
];

export default {
  setup() {
    return {
      services,
    };
  },
};
</script>

<template>
  <div class="flex mb-8">
    <div class="w-full p-5 rounded md:p-8 bg-light">
      <button
        aria-label="Close panel"
        class="inline-block outline-none focus:outline-none absolute end-4 top-4 z-[60] right-4"
        @click="closeModal()"
      >
        <span class="sr-only">close</span
        ><svg
          class="w-4 h-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <div class="flex justify-center">
        <a class="inline-flex">
          <span class="relative w-32 h-24 overflow-hidden md:w-40">
            <div class="box-border absolute inset-0 block m-0 overflow-hidden">
              <img
                alt="Get Grow Wealth"
                sizes="100vw"
                src="../assets/logo.png"
              />
              <!-- class="box-border absolute inset-0 block object-contain w-0 h-0 max-w-full max-h-full min-w-full min-h-full p-0 m-auto border-none" -->
            </div>
          </span>
        </a>
      </div>
      <div class="">
        <div class="p-4 mb-4 rounded-md bg-newbg">
          <div class="ml-3">
            <div class="flex flex-col">
              <span
                ><label class="font-bold">Beneficiary: </label> GetGrow Wealth
                Pvt. Ltd.
              </span>
              <span>
                <label class="font-bold">Bank Name: </label> HDFC Bank
              </span>
              <span>
                <label class="font-bold">Account Number: </label>
                50200062525305
              </span>
              <span> <label class="font-bold">IFSC: </label> HDFC0009340 </span>
              <span>
                <label class="font-bold">Address: </label>
                Ground Floor, H No. 3726, Sadguru Complex, Near Prasanna Hotel,
                Lanja - 416701
              </span>
              <span>
                <label class="font-bold">UPI ID: </label>7709363811@okbizaxis
              </span>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 gap-6">
          <div>
            <label
              class="block mb-3 text-sm font-semibold leading-none text-body-dark"
              >Pan Number</label
            ><input
              id="panNumber"
              name="panNumber"
              v-model="request.user.panNumber"
              type="text"
              class="flex items-center w-full h-12 px-4 text-sm uppercase transition duration-300 ease-in-out border rounded appearance-none text-heading focus:outline-none focus:ring-0 border-border-base focus:border-primary"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              aria-invalid="false"
            />
            <p class="mt-2 text-sm text-red-600" v-if="errors.panNumber">
              {{ errors.panNumber }}
            </p>
          </div>
          <div>
            <label
              class="block mb-3 text-sm font-semibold leading-none text-body-dark"
              >Aadhar Number</label
            ><input
              id="aadharNumber"
              name="aadharNumber"
              v-model="request.user.aadharNumber"
              type="text"
              class="flex items-center w-full h-12 px-4 text-sm transition duration-300 ease-in-out border rounded appearance-none text-heading focus:outline-none focus:ring-0 border-border-base focus:border-primary"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              aria-invalid="false"
            />
            <p class="mt-2 text-sm text-red-600" v-if="errors.aadharNumber">
              {{ errors.aadharNumber }}
            </p>
          </div>

          <div>
            <label
              for="bankAccountNumber"
              class="block mb-3 text-sm font-semibold leading-none text-body-dark"
              >Bank Account Number</label
            ><input
              id="bankAccountNumber"
              name="bankAccountNumber"
              v-model="request.user.bankAccountNumber"
              type="text"
              class="flex items-center w-full h-12 px-4 text-sm transition duration-300 ease-in-out border rounded appearance-none text-heading focus:outline-none focus:ring-0 border-border-base focus:border-primary"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              aria-invalid="false"
            />
            <p
              class="mt-2 text-sm text-red-600"
              v-if="errors.bankAccountNumber"
            >
              {{ errors.bankAccountNumber }}
            </p>
          </div>

          <div>
            <label
              for="ifsc"
              class="block mb-3 text-sm font-semibold leading-none text-body-dark"
            >
              IFSC</label
            ><input
              id="ifsc"
              name="ifsc"
              v-model="request.user.ifsc"
              type="text"
              class="flex items-center w-full h-12 px-4 text-sm uppercase transition duration-300 ease-in-out border rounded appearance-none text-heading focus:outline-none focus:ring-0 border-border-base focus:border-primary"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              aria-invalid="false"
            />
            <p class="mt-2 text-sm text-red-600" v-if="errors.ifsc">
              {{ errors.ifsc }}
            </p>
          </div>

          <div>
            <label
              for="nomineeName"
              class="block mb-3 text-sm font-semibold leading-none text-body-dark"
            >
              Nominee Name </label
            ><input
              id="nomineeName"
              name="nomineeName"
              v-model="request.user.nomineeName"
              type="text"
              class="flex items-center w-full h-12 px-4 text-sm transition duration-300 ease-in-out border rounded appearance-none text-heading focus:outline-none focus:ring-0 border-border-base focus:border-primary"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              aria-invalid="false"
            />
            <p class="mt-2 text-sm text-red-600" v-if="errors.nomineeName">
              {{ errors.nomineeName }}
            </p>
          </div>

          <div>
            <label
              for="nomineeDob"
              class="block mb-3 text-sm font-semibold leading-none text-body-dark"
              >Nominee DOB</label
            ><input
              id="nomineeDob"
              name="nomineeDob"
              v-model="request.user.nomineeDob"
              type="date"
              class="flex items-center w-full h-12 px-4 text-sm transition duration-300 ease-in-out border rounded appearance-none text-heading focus:outline-none focus:ring-0 border-border-base focus:border-primary"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              aria-invalid="false"
            />
          </div>
        </div>
        <div class="flex-row mt-6 mb-6 flex-2">
          <div class="flex-1">
            <label
              for="amount"
              class="block mb-3 text-sm font-semibold leading-none text-body-dark"
              >Amount To Be Paid</label
            >
            <div class="mt-1">
              <input
                id="amount"
                name="amount"
                :value="fee"
                disabled
                type="text"
                class="flex items-center w-full h-12 px-4 text-sm transition duration-300 ease-in-out bg-gray-100 border border-gray-200 rounded shadow-none appearance-none text-slate-500 "
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                aria-invalid="false"
              />
            </div>
          </div>
        </div>

        <div class="flex-row mt-6 mb-6 flex-2">
          <div v-if="showDetails" class="p-4 mb-4 rounded-md bg-newbg">
            <div class="flex">
              <div class="flex-1 ml-3 md:flex md:justify-between">
                <p class="text-sm ">
                  After your successful transaction with our given account
                  number, please share your transaction reference code here.
                </p>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <div class="flex">
              <label
                for="transactionReference"
                class="block mb-3 text-sm font-semibold leading-none text-body-dark"
                >Your Transaction Reference Code</label
              >
              <button @click="showDetails = !showDetails">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 mb-1 ml-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                  />
                </svg>
              </button>
            </div>

            <input
              id="transactionReference"
              name="transactionReference"
              v-model="request.transactionReferenceCode"
              type="text"
              class="flex items-center w-full h-12 px-4 text-sm transition duration-300 ease-in-out border rounded appearance-none text-heading focus:outline-none focus:ring-0 border-border-base focus:border-primary"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              aria-invalid="false"
            />
            <p class="mt-2 text-sm text-red-600" v-if="errors.transactionCode">
              {{ errors.transactionCode }}
            </p>
          </div>
        </div>

        <div class="flex-row mb-6 flex-2">
          <div class="flex-1">
            <label
              for="paymentMode"
              class="block mb-3 text-sm font-semibold leading-none text-body-dark"
              >Select Payment Mode</label
            >
            <div class="mt-1">
              <select
                v-model="request.paymentModeId"
                id="paymentMode"
                name="paymentMode"
                class="block w-full h-12 px-3 py-2 placeholder-gray-400 border rounded appearance-none text-heading focus:outline-none sm:text-sm focus:ring-0 border-border-base focus:border-primary"
              >
                <option
                  v-for="item in paymentModes"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
              <p class="mt-2 text-sm text-red-600" v-if="errors.paymentMode">
                {{ errors.paymentMode }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8">
        <button
          type="submit"
          class="inline-flex items-center justify-center flex-shrink-0 w-full px-5 py-0 font-semibold leading-none text-white transition duration-300 ease-in-out border border-transparent rounded outline-none focus:outline-none focus:shadow focus:ring-1 focus:ring-primary-700 bg-primary text-light hover:bg-primary-hover h-11 sm:h-12"
          @click="submit()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from 'date-fns';
import { mapActions, mapMutations, mapState } from 'vuex';
import AuthMiddleware from '../middleware/Auth';
export default {
  props: {
    selectedCourseId: {
      type: Number,
      required: true,
    },
    fee: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      errors: {},
      showDetails: false,
      paymentModes: [],
      request: {
        user: {
          panNumber: '',
          aadharNumber: '',
          ifsc: '',
          bankAccountNumber: '',
          nomineeName: '',
          nomineeDob: format(new Date(), 'yyyy-MM-dd'),
        },
        transactionReferenceCode: '',
        paymentModeId: '',
      },
    };
  },
  computed: {
    ...mapState('auth', ['loggedInUser']),
  },
  mounted() {
    this.getPaymentModes().then((res) => {
      this.paymentModes = res.filter((i) =>
        ['upi', 'banktransfer', 'cheque'].includes(i.code)
      );
    });
    if (this.loggedInUser && this.loggedInUser.id) {
      this.request = {
        user: {
          panNumber: this.loggedInUser.panNumber,
          aadharNumber: this.loggedInUser.aadharNumber,
          ifsc: this.loggedInUser.ifsc,
          bankAccountNumber: this.loggedInUser.bankAccountNumber,
          nomineeName: this.loggedInUser.nomineeName,
          nomineeDob: format(
            new Date(this.loggedInUser.nomineeDob),
            'yyyy-MM-dd'
          ),
        },
      };
    }
  },
  methods: {
    ...mapActions('auth', ['getPaymentModes']),
    ...mapActions('order', ['saveOrder']),
    ...mapMutations('notification', ['setNotification']),
    closeModal() {
      this.$emit('cancle');
    },
    submit() {
      const hasErrors = this.validateForm();
      if (!hasErrors) {
        const user = AuthMiddleware.getUser();
        this.request.userId = user.id;
        this.request.courseId = this.selectedCourseId;
        this.saveOrder(this.request).then(() => {
          this.closeModal();
          this.setNotification({
            title: 'Course Request Added!',
            subtitle: 'Admin Verifiation Pending',
            type: 'success',
          });
        });
      }
    },
    validateForm() {
      this.errors = {};
      if (
        this.request.user.panNumber === '' ||
        this.request.user.panNumber === null ||
        this.request.user.panNumber === undefined
      ) {
        this.errors.panNumber = 'Pan number is required!';
      }

      if (
        this.request.user.aadharNumber === '' ||
        this.request.user.aadharNumber === null ||
        this.request.user.aadharNumber === undefined
      ) {
        this.errors.aadharNumber = 'Aadhar number is required!';
      }

      if (
        this.request.user.bankAccountNumber === '' ||
        this.request.user.bankAccountNumber === null ||
        this.request.user.bankAccountNumber === undefined
      ) {
        this.errors.bankAccountNumber = 'Bank account number is required!';
      }

      if (
        this.request.user.ifsc === '' ||
        this.request.user.ifsc === null ||
        this.request.user.ifsc === undefined
      ) {
        this.errors.ifsc = 'IFSC is required!';
      }

      if (
        this.request.user.nomineeName === '' ||
        this.request.user.nomineeName === null ||
        this.request.user.nomineeName === undefined
      ) {
        this.errors.nomineeName = 'Nominee name is required!';
      }
      if (
        this.request.transactionReferenceCode === '' ||
        this.request.transactionReferenceCode === null ||
        this.request.transactionReferenceCode === undefined
      ) {
        this.errors.transactionReferenceCode = 'Transaction code is required!';
      }

      if (
        this.request.paymentModeId === '' ||
        this.request.paymentModeId === null ||
        this.request.paymentModeId === undefined
      ) {
        this.errors.paymentMode = 'Select Payment Mode!';
      }

      const hasErrors = Boolean(Object.keys(this.errors).length);

      return hasErrors;
    },
  },
};
</script>

<template>
  <div>
    <header-section
      v-if="!$route.meta.isAdmin && $route.name != 'Profile'"
    ></header-section>

    <router-view></router-view>
    <footer-section v-if="!$route.meta.isAdmin"></footer-section>
  </div>
</template>
<script>
import HeaderSection from './HeaderSection.vue';
import FooterSection from './FooterSection.vue';
export default {
  components: {
    HeaderSection,
    FooterSection,
  },
};
</script>

import actions from './actions';
import mutations from './mutations';
const state = {
  users: [],
  orders: [],
  counts: {},
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};

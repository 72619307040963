import actions from './action';
import mutations from './mutations';
const state = {
  courses: [],
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};

import { createRouter, createWebHistory } from 'vue-router';
import Courses from '../views/Courses.vue';
import LandingPage from '../views/LandingPage.vue';
import AdminView from '../layout/AdminPanel.vue';
import adminRoutes from './adminRoutes';
import AccessDenied from '../views/AccessDenied.vue';
import Profile from '../views/Profile.vue';
import AdditionalCourses from '../views/AdditionalCourses.vue';

function checkIfAdmin() {
  const userObj = window.localStorage.getItem('user') || '';
  let user;
  if (userObj) {
    user = JSON.parse(userObj);
    if (user.isAdmin === true) {
      return true;
    }
  }
  return { path: '/403' };
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: LandingPage,
  },
  {
    path: '/financial-literacy',
    name: 'financial-literacy',
    component: Courses,
  },

  {
    path: '/admin',
    name: 'Admin',
    component: AdminView,
    meta: { isAdmin: true },
    children: adminRoutes,
    beforeEnter: [checkIfAdmin],
  },
  {
    path: '/403',
    name: 'Access Denied',
    component: AccessDenied,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  {
    path: '/other-courses',
    name: 'Other Courses',
    component: AdditionalCourses,
  },
];

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});
export default router;

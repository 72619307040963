import mutations from './mutation';

const state = {
  notification: {
    title: '',
    subtitle: '',
    type: '',
    isVisible: false,
  },
};
export default {
  state,
  mutations,
  namespaced: true,
};

import { camelCase } from 'lodash';
const isArray = (a) => Array.isArray(a);

const isObject = (o) =>
  o === Object(o) && !isArray(o) && typeof o !== 'function';
const snakeToCamel = (o) => {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach((k) => {
      n[camelCase(k)] = snakeToCamel(o[k]);
    });

    return n;
  }
  if (isArray(o)) {
    return o.map((i) => snakeToCamel(i));
  }
  return o;
};

export default snakeToCamel;

import { createStore } from 'vuex';

import course from './modules/courses';
import notification from './modules/notification';
import auth from './modules/auth';
import order from './modules/order';
import admin from './modules/admin';

export const store = createStore({
  modules: {
    course,
    notification,
    auth,
    order,
    admin,
  },
});

import axios from '../../../middleware/Axios';
import camelToSnake from '../../../utils/camelToSnake';

const actions = {
  saveOrder(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/orders', camelToSnake(payload))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default actions;

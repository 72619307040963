import axios from '../../../middleware/Axios';

const actions = {
  getUsers(context) {
    return new Promise((resolve, reject) => {
      axios
        .get('api/v1/admin/users')
        .then((res) => {
          context.commit('setUsers', res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getOrders(context) {
    return new Promise((resolve, reject) => {
      axios
        .get('api/v1/admin/orders')
        .then((res) => {
          context.commit('setOrders', res.data.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateOrder(_, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`api/v1/admin/orders/${payload.id}`, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCounts(context) {
    return new Promise((resolve, reject) => {
      axios
        .get('api/v1/admin/count')
        .then((res) => {
          context.commit('setCounts', res.data);
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default actions;

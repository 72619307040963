<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div
    aria-live="assertive"
    class="fixed inset-0 z-50 flex items-end px-4 py-6 mt-5 pointer-events-none  sm:p-6 sm:items-start"
  >
    <div class="flex flex-col items-center w-full space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transition duration-300 ease-out transform"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition duration-300 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="notification.isVisible"
          class="w-full max-w-sm mt-12 overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto  ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <CheckCircleIcon
                  v-if="notification.type === 'success'"
                  class="w-6 h-6 text-green-400"
                  aria-hidden="true"
                />
                <XCircleIcon
                  v-else
                  class="w-6 h-6 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ notification.title }}
                </p>
                <p class="mt-1 text-sm text-gray-500">
                  {{ notification.subtitle }}
                </p>
              </div>
              <div class="flex flex-shrink-0 ml-4">
                <button
                  @click="closeNotification()"
                  class="inline-flex text-gray-400 bg-white rounded-md  hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="w-5 h-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/outline';
import { XIcon } from '@heroicons/vue/solid';
import { mapMutations, mapState } from 'vuex';
export default defineComponent({
  components: {
    CheckCircleIcon,
    XIcon,
    XCircleIcon,
  },
  computed: {
    ...mapState('notification', ['notification']),
  },
  watch: {
    notification: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value.isVisible === true) {
          setTimeout(() => {
            this.resetState();
          }, 3000);
        }
      },
    },
  },
  methods: {
    ...mapMutations('notification', ['resetState']),
    closeNotification() {
      this.resetState();
    },
  },
});
</script>

<template>
  <div>
    <organization-chart
      :datasource="ds"
      :pan="true"
      :zoom="true"
      zoomin-limit="7"
      zoomout-limit="0.5"
      @node-click="getNodeData"
    >
      <!-- <template :slot-scope="{ nodeData }">
        {{ nodeData }}
      </template> -->
    </organization-chart>
  </div>
</template>

<script>
import OrganizationChart from 'vue3-organization-chart';
import 'vue3-organization-chart/dist/orgchart.css';
import { mapActions, mapState } from 'vuex';
import Auth from '../middleware/Auth';
export default {
  components: {
    OrganizationChart,
  },
  data() {
    return {
      ds: {
        // id: '1',
        // name: 'Lao Lao',
        // title: 'general manager',
        // children: [
        //   { id: '2', name: 'Bo Miao', title: 'department manager' },
        //   {
        //     id: '3',
        //     name: 'Su Miao',
        //     title: 'department manager',
        //     children: [
        //       { id: '4', name: 'Tie Hua', title: 'senior engineer' },
        //       {
        //         id: '5',
        //         name: 'Hei Hei',
        //         title: 'senior engineer',
        //         children: [
        //           { id: '6', name: 'Pang Pang', title: 'engineer' },
        //           { id: '7', name: 'Xiang Xiang', title: 'UE engineer' },
        //         ],
        //       },
        //     ],
        //   },
        //   { id: '8', name: 'Hong Miao', title: 'department manager' },
        //   { id: '9', name: 'Chun Miao', title: 'department manager' },
        // ],
      },
    };
  },
  computed: {
    ...mapState('auth', ['loggedInUser']),
  },
  mounted() {
    const user = Auth.getUser();
    this.getTree(user.id);
  },
  methods: {
    ...mapActions('auth', ['getUserTree']),

    getTree(userId) {
      this.getUserTree(userId).then((res) => {
        this.ds = res;
      });
    },
    getNodeData(node) {
      console.log('item', node);
    },
  },
};
</script>

import actions from './actions';

const state = {
  order: {},
  orders: [],
};

export default {
  namespaced: true,
  state,
  actions,
};

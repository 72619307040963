<template>
  <div class="relative bg-gray-50">
    <Popover class="relative bg-white shadow">
      <div class="px-2 mx-auto sm:px-4">
        <div
          class="flex items-center justify-between py-6 md:justify-start md:space-x-10"
        >
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <a href="/">
              <img class="w-auto h-8 sm:h-14" src="../assets/logo3.png" />
            </a>
          </div>
          <div class="-my-2 -mr-2 md:hidden">
            <PopoverButton
              class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            >
              <span class="sr-only">Open menu</span>
              <MenuIcon class="w-6 h-6" aria-hidden="true" />
            </PopoverButton>
          </div>
          <PopoverGroup as="nav" class="hidden space-x-6 md:flex">
            <Popover class="relative" v-slot="{ open }">
              <PopoverButton
                :class="[
                  open ? 'text-gray-900' : 'text-gray-500',
                  'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary',
                ]"
              >
                <span class="break-words">Financial Services</span>
                <ChevronDownIcon
                  :class="[
                    open ? 'text-gray-600' : 'text-gray-400',
                    'h-5 w-5 group-hover:text-gray-500',
                  ]"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="translate-y-1 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="transition duration-150 ease-in"
                leave-from-class="translate-y-0 opacity-100"
                leave-to-class="translate-y-1 opacity-0"
              >
                <PopoverPanel
                  class="absolute z-10 w-screen max-w-md px-2 mt-3 -ml-4 transform sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                >
                  <div
                    class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                  >
                    <div
                      class="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8"
                    >
                      <a
                        v-for="item in financialServices"
                        :key="item.name"
                        :href="item.href"
                        class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                      >
                        <component
                          :is="item.icon"
                          class="flex-shrink-0 w-6 h-6 text-darkPrimary"
                          aria-hidden="true"
                        />
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            {{ item.name }}
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            {{ item.description }}
                          </p>
                        </div>
                      </a>
                    </div>
                    <div
                      class="px-5 py-5 space-y-6 bg-gray-50 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8"
                    >
                      <div
                        v-for="item in callsToAction"
                        :key="item.name"
                        class="flow-root"
                      >
                        <a
                          :href="item.href"
                          class="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100"
                        >
                          <component
                            :is="item.icon"
                            class="flex-shrink-0 w-6 h-6 text-gray-400"
                            aria-hidden="true"
                          />
                          <span class="ml-3">{{ item.name }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>

            <a
              href="/financial-literacy"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Financial Literacy
            </a>

            <a
              href="/other-courses"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              Other Courses
            </a>

            <Popover class="relative" v-slot="{ open }">
              <PopoverButton
                :class="[
                  open ? 'text-gray-900' : 'text-gray-500',
                  'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary',
                ]"
              >
                <span>Legal Services</span>
                <ChevronDownIcon
                  :class="[
                    open ? 'text-gray-600' : 'text-gray-400',
                    'h-5 w-5 group-hover:text-gray-500',
                  ]"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="translate-y-1 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="transition duration-150 ease-in"
                leave-from-class="translate-y-0 opacity-100"
                leave-to-class="translate-y-1 opacity-0"
              >
                <PopoverPanel
                  class="absolute z-10 w-screen max-w-md px-2 mt-3 -ml-4 transform sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                >
                  <div
                    class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                  >
                    <div
                      class="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8"
                    >
                      <a
                        v-for="item in legalServices"
                        :key="item.name"
                        :href="item.href"
                        class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                      >
                        <component
                          :is="item.icon"
                          class="flex-shrink-0 w-6 h-6 text-darkPrimary"
                          aria-hidden="true"
                        />
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            {{ item.name }}
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            {{ item.description }}
                          </p>
                        </div>
                      </a>
                    </div>
                    <div
                      class="px-5 py-5 space-y-6 bg-gray-50 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8"
                    >
                      <div
                        v-for="item in callsToAction"
                        :key="item.name"
                        class="flow-root"
                      >
                        <a
                          :href="item.href"
                          class="flex items-center p-3 -m-3 text-base font-medium text-gray-900 rounded-md hover:bg-gray-100"
                        >
                          <component
                            :is="item.icon"
                            class="flex-shrink-0 w-6 h-6 text-gray-400"
                            aria-hidden="true"
                          />
                          <span class="ml-3">{{ item.name }}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>

            <a
              href="#"
              class="text-base font-medium text-gray-500 hover:text-gray-900"
            >
              E-Mall
            </a>

            <Popover class="relative" v-slot="{ open }">
              <PopoverButton
                :class="[
                  open ? 'text-gray-900' : 'text-gray-500',
                  'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary',
                ]"
              >
                <span>Digital Marketing</span>
                <ChevronDownIcon
                  :class="[
                    open ? 'text-gray-600' : 'text-gray-400',
                    'h-5 w-5 group-hover:text-gray-500',
                  ]"
                  aria-hidden="true"
                />
              </PopoverButton>

              <transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="translate-y-1 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="transition duration-150 ease-in"
                leave-from-class="translate-y-0 opacity-100"
                leave-to-class="translate-y-1 opacity-0"
              >
                <PopoverPanel
                  class="absolute z-10 w-screen max-w-md px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
                >
                  <div
                    class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                  >
                    <div
                      class="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8"
                    >
                      <a
                        v-for="item in digitalMarketing"
                        :key="item.name"
                        :href="item.href"
                        class="flex items-start p-3 -m-3 rounded-lg hover:bg-gray-50"
                      >
                        <component
                          :is="item.icon"
                          class="flex-shrink-0 w-6 h-6 text-darkPrimary"
                          aria-hidden="true"
                        />
                        <div class="ml-4">
                          <p class="text-base font-medium text-gray-900">
                            {{ item.name }}
                          </p>
                          <p class="mt-1 text-sm text-gray-500">
                            {{ item.description }}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>
          </PopoverGroup>

          <div class="items-center justify-end hidden md:flex md:flex-1 lg:w-0">
            <button
              v-if="!loggedInUser.id"
              class="inline-flex items-center justify-center px-4 py-2 ml-2 text-base font-medium text-white border border-transparent rounded-md shadow-sm whitespace-nowrap bg-darkPrimary hover:bg-primary"
              @click="join()"
            >
              Join
            </button>
            <Popover v-else class="relative" v-slot="{ open }">
              <PopoverButton
                class="inline-flex items-center justify-center w-10 h-10 ml-2 text-base font-medium rounded-full group bg-primary "
                :class="open ? 'text-gray-900' : 'text-gray-500'"
              >
                <span
                  class="text-lg font-bold leading-none text-white capitalize"
                  >{{ loggedInUser.name.charAt(0) }}</span
                >
              </PopoverButton>

              <transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="translate-y-1 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="transition duration-150 ease-in"
                leave-from-class="translate-y-0 opacity-100"
                leave-to-class="translate-y-1 opacity-0"
              >
                <PopoverPanel
                  class="absolute z-10 w-screen max-w-xs px-2 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0"
                >
                  <div
                    class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
                  >
                    <div
                      class="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-4"
                    >
                      <a
                        @click="goToProfile()"
                        class="block p-3 -m-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                        ]"
                      >
                        <p class="text-base font-medium text-gray-900">
                          Profile
                        </p>
                      </a>
                      <a
                        v-if="loggedInUser.isAdmin === true"
                        @click="goToAdminPanel()"
                        class="block p-3 -m-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50"
                      >
                        <p class="text-base font-medium text-gray-900">
                          Admin Panel
                        </p>
                      </a>
                      <a
                        @click="logout()"
                        class="block p-3 -m-3 transition duration-150 ease-in-out rounded-md hover:bg-gray-50"
                      >
                        <p class="text-base font-medium text-gray-900">
                          Log Out
                        </p>
                      </a>
                    </div>
                  </div>
                </PopoverPanel>
              </transition>
            </Popover>
          </div>
        </div>
      </div>
    </Popover>
    <modal @close="openModal = false" :visibility="openModal">
      <template v-slot:content>
        <auth @cancle="openModal = false" />
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from '../components/Modal.vue';
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import {
  MenuIcon,
  XIcon,
  TrendingUpIcon,
  DocumentTextIcon,
  UsersIcon,
  CurrencyDollarIcon,
  CollectionIcon,
  OfficeBuildingIcon,
  CalculatorIcon,
  ReceiptTaxIcon,
  DocumentAddIcon,
  ViewBoardsIcon,
  ViewGridIcon,
} from '@heroicons/vue/outline';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import Auth from './Auth.vue';
const financialServices = [
  {
    name: 'Share Market',
    href: '/#financial-section',
    description: 'Broking Equity, Commodity Currency, IPO',
    icon: TrendingUpIcon,
  },
  {
    name: 'Insurance',
    href: '/#financial-section',
    description: 'Life, Health, General',
    icon: DocumentTextIcon,
  },

  {
    name: 'NPS',
    href: '/#financial-section',
    description:
      'Build strategic funnels that will drive your customers to convert',
    icon: UsersIcon,
  },
  {
    name: 'Mutual Fund',
    href: '/#financial-section',
    description:
      'Build strategic funnels that will drive your customers to convert',
    icon: CurrencyDollarIcon,
  },
];
const digitalMarketing = [
  {
    name: 'Digital Marketing Services',
    href: '/#digital-marketing',
    description: 'Logo Design, Banner Design',
    icon: TrendingUpIcon,
  },
];
const legalServices = [
  {
    name: 'Accounting',
    href: '/#legal-section',
    description: 'Book Keeping, Auditing, Tax Accounting',
    icon: CalculatorIcon,
  },
  {
    name: 'Tax',
    href: '/#legal-section',
    description: 'TDS, TDS Return ITR',
    icon: ReceiptTaxIcon,
  },
  {
    name: 'ROC Services',
    href: '/#legal-section',
    description: 'Incorporation Of Company etc',
    icon: DocumentAddIcon,
  },
  {
    name: 'GST',
    href: '/#legal-section',
    description: 'GST Consultant, GST Registration etc',
    icon: CollectionIcon,
  },
];
const realEstates = [
  {
    name: 'Bungalow',
    href: '/#real-estate',
    description: 'Broking Equity, Commodity Currency, IPO',
    icon: CollectionIcon,
  },
  {
    name: 'Agriculture Plots',
    href: '/#real-estate',
    description: 'Broking Equity, Commodity Currency, IPO',
    icon: ViewGridIcon,
  },
  {
    name: 'Flats',
    href: '/#real-estate',
    description: 'Broking Equity, Commodity Currency, IPO',
    icon: OfficeBuildingIcon,
  },
  {
    name: 'Resale Plots',
    href: '/#real-estate',
    description: 'Broking Equity, Commodity Currency, IPO',
    icon: ViewBoardsIcon,
  },
];
import { mapMutations, mapState } from 'vuex';
import AuthMiddleware from '../middleware/Auth';

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChevronDownIcon,
    MenuIcon,
    XIcon,
    auth: Auth,
    Modal,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  setup() {
    return {
      financialServices,
      digitalMarketing,
      legalServices,
      realEstates,
    };
  },
  data: () => {
    return {
      openModal: false,
      userOptions: [{ name: 'Log out', href: '#' }],
    };
  },
  computed: {
    ...mapState('auth', ['loggedInUser']),
  },

  methods: {
    ...mapMutations('notification', ['setNotification']),
    ...mapMutations('auth', ['setLoggedInUser']),
    join() {
      this.openModal = true;
    },
    logout() {
      AuthMiddleware.logout();
      this.setLoggedInUser({});
      this.setNotification({
        title: 'Logged Out',
        type: 'success',
      });
    },
    goToAdminPanel() {
      this.$router.push('/admin/dashboard');
    },
    goToProfile() {
      this.$router.push('/profile');
    },
  },
};
</script>

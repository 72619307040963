import axios from './Axios';
class Auth {
  token;
  npm;
  user;
  constructor() {
    const token = window.localStorage.getItem('token');
    const user = window.localStorage.getItem('user');

    this.token = token;
    this.user = user ? JSON.parse(user) : null;
    if (this.token) {
      Auth.updateAxiosToken(this.token);
    }
  }

  static updateAxiosToken(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  setToken(token) {
    window.localStorage.setItem('token', token);
    this.token = token;
    Auth.updateAxiosToken(this.token);
  }

  setUser(user) {
    window.localStorage.setItem('user', JSON.stringify(user));
    this.user = user;
  }

  static getUser() {
    const userObj = window.localStorage.getItem('user') || '';
    let user = {};
    if (userObj) {
      user = JSON.parse(userObj);
    }
    return user;
  }

  static logout() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
    this.token = null;
    this.user = null;
    Auth.updateAxiosToken(null);
  }
}

export default Auth;

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div class="px-4 py-24 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col sm:align-center">
        <h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">
          Courses
        </h1>
        <p class="mt-5 text-xl text-gray-500 sm:text-center">
          Develope your skill and be economically independant with us.
        </p>
      </div>
      <div
        class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 md:grid-cols-4"
      >
        <div v-for="(item, index) in courses" :key="index">
          <img
            :src="item.image"
            @click="openModal(item.image)"
            class="cursor-pointer"
          />
          <button
            class="block w-full py-2 mt-8 text-sm font-semibold text-center text-white border rounded-md border-primary bg-primary hover:bg-darkPrimary"
            @click="checkIfLoggedIn(item)"
          >
            <div>
              <div
                v-if="
                  this.loggedInUser &&
                    this.loggedInUser.id &&
                    this.loggedInUser.orders.length &&
                    this.loggedInUser.orders.some(
                      (i) => i.courseId == item.id && i.flag === 1
                    )
                "
                class="flex justify-center"
              >
                <span> Waiting For Approval</span>
              </div>
              <div
                v-else-if="
                  this.loggedInUser &&
                    this.loggedInUser.id &&
                    this.loggedInUser.orders.length &&
                    this.loggedInUser.orders.some(
                      (i) => i.courseId == item.id && i.flag === 2
                    )
                "
                class="flex justify-center"
              >
                <CheckCircleIcon class="w-5 h-5"></CheckCircleIcon>
                <span> Purchased</span>
              </div>
              <span v-else> Buy {{ item.title }} Course</span>
            </div>
          </button>
        </div>
      </div>
    </div>
    <!-- login or registration  modal -->
    <modal @close="openAuthModal = false" :visibility="openAuthModal">
      <template v-slot:content>
        <auth @cancle="openAuthModal = false" />
      </template>
    </modal>
    <!-- payment details modal -->
    <modal @close="openPaymentModal = false" :visibility="openPaymentModal">
      <template v-slot:content>
        <payment-details
          @cancle="openPaymentModal = false"
          :selectedCourseId="selectedCourseId"
          :fee="fee"
        ></payment-details>
      </template>
    </modal>

    <modal @close="openImageModal = false" :visibility="openImageModal">
      <template v-slot:content>
        <!-- <img src="../assets/images/bakery.png" alt="" /> -->
        <img :src="selectedImage" alt="" />
      </template>
    </modal>
  </div>
</template>

<script>
import { CheckCircleIcon } from '@heroicons/vue/solid';
import { mapActions, mapState } from 'vuex';
// import AuthMiddleware from '../middleware/Auth';
import Modal from '../components/Modal.vue';
import Auth from './Auth.vue';
import PaymentDetails from './PaymentDetails.vue';
export default {
  components: {
    CheckCircleIcon,
    Modal,
    Auth,
    PaymentDetails,
  },

  data() {
    return {
      selectedCourseId: null,
      purchasedCourseId: null,
      fee: null,
      openAuthModal: false,
      openPaymentModal: false,
      openImageModal: false,
      selectedImage: '',
    };
  },
  mounted() {
    this.getCourses({ type: 'regular' });
    if (this.loggedInUser.id) {
      this.getUser(this.loggedInUser.id);
      this.checkOrders();
    }
  },
  computed: {
    ...mapState('course', ['courses']),
    ...mapState('auth', ['loggedInUser']),
  },
  methods: {
    ...mapActions('course', ['getCourses']),
    ...mapActions('auth', ['getUser']),

    checkIfLoggedIn(course) {
      if (this.loggedInUser && this.loggedInUser.id) {
        this.openPaymentModal = true;
        this.selectedCourseId = course.id;
        this.fee = course.fee;
      } else {
        this.openAuthModal = true;
      }
    },
    checkOrders() {
      if (this.loggedInUser.orders.length) {
        this.purchasedCourseId = this.loggedInUser.orders[0].courseId;
      }
    },
    openModal(payload) {
      this.selectedImage = payload;
      this.openImageModal = true;
    },
  },
};
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <h2 class="text-xs font-medium tracking-wide text-gray-500 uppercase">
      Overview
    </h2>
    <ul
      class="grid grid-cols-1 gap-5 mt-3 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4"
    >
      <li class="flex col-span-1 rounded-md shadow-sm">
        <div
          class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white bg-purple-600 rounded-l-md"
        >
          US
        </div>
        <div
          class="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md"
        >
          <div class="flex-1 px-4 py-2 text-sm truncate ">
            <a
              class="font-medium text-gray-900 hover:text-gray-600"
              href="/admin/users"
              >Users</a
            >
            <p class="text-gray-500">{{ counts.userCount }} Members</p>
          </div>
          <!-- <div class="flex-shrink-0 pr-2">
            <button
              class="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-transparent bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="sr-only">Open options</span>
              <DotsVerticalIcon class="w-5 h-5" aria-hidden="true" />
            </button>
          </div> -->
        </div>
      </li>
      <li class="flex col-span-1 rounded-md shadow-sm">
        <div
          class="flex items-center justify-center flex-shrink-0 w-16 text-sm font-medium text-white bg-pink-600 rounded-l-md"
        >
          OR
        </div>
        <div
          class="flex items-center justify-between flex-1 truncate bg-white border-t border-b border-r border-gray-200 rounded-r-md"
        >
          <div class="flex-1 px-4 py-2 text-sm truncate">
            <a
              class="font-medium text-gray-900 hover:text-gray-600"
              href="/admin/orders"
              >Orders</a
            >
            <p class="text-gray-500">{{ counts.orderCount }}</p>
          </div>
          <!-- <div class="flex-shrink-0 pr-2">
            <button
              class="inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-transparent bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span class="sr-only">Open options</span>
              <DotsVerticalIcon class="w-5 h-5" aria-hidden="true" />
            </button>
          </div> -->
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
// import { DotsVerticalIcon } from '@heroicons/vue/solid';
import { mapActions, mapState } from 'vuex';

const projects = [
  {
    name: 'Graph API',
    initials: 'GA',
    href: '#',
    members: 16,
    bgColor: 'bg-pink-600',
  },
  {
    name: 'Component Design',
    initials: 'CD',
    href: '#',
    members: 12,
    bgColor: 'bg-purple-600',
  },
  {
    name: 'Templates',
    initials: 'T',
    href: '#',
    members: 16,
    bgColor: 'bg-yellow-500',
  },
  {
    name: 'React Components',
    initials: 'RC',
    href: '#',
    members: 8,
    bgColor: 'bg-green-500',
  },
];

export default {
  components: {
    // DotsVerticalIcon,
  },
  setup() {
    return {
      projects,
    };
  },
  computed: {
    ...mapState('admin', ['counts']),
  },
  mounted() {
    this.getCounts();
  },
  methods: {
    ...mapActions('admin', ['getCounts']),
  },
};
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div id="real-estate" class="py-12 bg-gray-100">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="lg:text-center">
        <h2
          class="text-base font-semibold tracking-wide uppercase text-primary"
        >
          Real Estate
        </h2>
        <p
          class="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-secondary sm:text-4xl"
        >
          The best investment on earth is earth
        </p>
        <p class="max-w-2xl mt-4 text-xl text-gray-500 lg:mx-auto">
          There are various types of real estate services in market. We provide
          you four types of real estate service given below
        </p>
      </div>

      <div class="mt-10">
        <dl
          class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10"
        >
          <div v-for="feature in features" :key="feature.name" class="relative">
            <dt>
              <div
                class="absolute flex items-center justify-center w-12 h-12 text-white rounded-md bg-darkPrimary"
              >
                <component
                  :is="feature.icon"
                  class="w-6 h-6"
                  aria-hidden="true"
                />
              </div>
              <p class="ml-16 text-lg font-medium leading-6 text-gray-900">
                {{ feature.name }}
              </p>
            </dt>
            <dd class="mt-2 ml-16 text-base text-gray-500">
              {{ feature.description }}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CollectionIcon,
  ViewGridIcon,
  OfficeBuildingIcon,
  ViewBoardsIcon,
} from '@heroicons/vue/outline';

const features = [
  {
    name: 'Bungalow',
    description: 'Broking Equity, Commodity Currency, IPO',
    icon: CollectionIcon,
  },
  {
    name: 'Agriculture Plots',
    description: 'Broking Equity, Commodity Currency, IPO',
    icon: ViewGridIcon,
  },
  {
    name: 'Flats',
    description: 'Broking Equity, Commodity Currency, IPO',
    icon: OfficeBuildingIcon,
  },
  {
    name: 'Resale Plots',
    description: 'Broking Equity, Commodity Currency, IPO',
    icon: ViewBoardsIcon,
  },
];

export default {
  setup() {
    return {
      features,
    };
  },
};
</script>

<template>
  <div>
    <p class="mt-4 mb-8 text-sm text-center md:text-base text-body sm:mt-5">
      Login with your Phone &amp; password
    </p>
    <div class="mb-5">
      <label
        for="phone"
        class="block mb-3 text-sm font-semibold leading-none text-left text-body-dark"
        >Phone</label
      ><input
        id="phone"
        name="phone"
        type="text"
        class="flex items-center w-full h-12 px-4 text-sm transition duration-300 ease-in-out border rounded appearance-none text-heading focus:outline-none focus:ring-0 border-border-base focus:border-primary"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        aria-invalid="false"
        v-model="data.phone"
      />
    </div>
    <div class="mb-5">
      <div class="flex items-center justify-between mb-2">
        <label for="password" class="text-sm font-semibold text-body"
          >Password</label
        >
        <!-- <button
          type="button"
          class="text-xs transition-colors duration-200 text-primary focus:outline-none focus:text-primary-700 focus:font-semibold hover:text-primary-hover"
        >
          Forgot password?
        </button> -->
      </div>
      <div class="relative">
        <input
          id="password"
          name="password"
          :type="showPassword ? 'text' : 'password'"
          formControlName="password"
          class="w-full py-3 text-sm transition duration-300 ease-in-out border rounded appearance-none ps-4 pe-11 text-heading focus:outline-none focus:ring-0 border-border-base focus:border-primary"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          v-model="data.password"
        /><label
          for="password"
          class="absolute -mt-2 cursor-pointer end-4 top-5 text-body right-3"
          ><button v-if="!showPassword" @click="showPassword = true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              ></path>
            </svg>
          </button>
          <button v-else @click="showPassword = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
              />
            </svg>
          </button>
        </label>
      </div>
    </div>
    <div class="mt-8">
      <button
        type="submit"
        class="inline-flex items-center justify-center flex-shrink-0 w-full px-5 py-0 font-semibold leading-none text-white transition duration-300 ease-in-out border border-transparent rounded outline-none focus:outline-none focus:shadow focus:ring-1 focus:ring-primary-700 bg-primary text-light hover:bg-primary-hover h-11 sm:h-12"
        @click="signIn()"
      >
        Login
      </button>
    </div>
    <div
      class="relative flex flex-col items-center justify-center mt-8 mb-6 text-sm text-heading sm:mt-11 sm:mb-8"
    ></div>

    <div class="text-sm text-center sm:text-base text-body">
      Don't have any account?
      <button
        @click="showRegisterForm()"
        class="font-semibold underline transition-colors duration-200 ms-1 text-primary focus:outline-none hover:text-primary-hover focus:text-primary-hover hover:no-underline focus:no-underline"
      >
        Register
      </button>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapActions } from 'vuex';

export default {
  data() {
    return {
      showPassword: false,
      data: {
        phone: '',
        password: '',
      },
    };
  },
  methods: {
    ...mapMutations('notification', ['setNotification']),
    ...mapActions('auth', ['login']),
    showRegisterForm() {
      this.$emit('showRegister', true);
    },
    signIn() {
      // this.$store.dispatch('auth/login', null, { root: true });
      this.login(this.data).then(() => {
        this.setNotification({
          title: 'Logged In Successfully!',
          type: 'success',
        });
        this.$emit('close');
      });
    },
  },
};
</script>

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div class="px-4 py-24 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div class="sm:flex sm:flex-col sm:align-center">
        <h1 class="text-5xl font-extrabold text-gray-900 sm:text-center">
          Financial Literacy
        </h1>
        <p class="mt-5 text-xl text-gray-500 sm:text-center">
          Start your journey in the world of stock market with us.
        </p>
      </div>
      <div
        class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 md:grid-cols-3"
      >
        <div
          v-for="item in courses"
          :key="item.id"
          class="border border-gray-200 divide-y divide-gray-200 rounded-lg shadow-sm"
        >
          <div class="p-6">
            <h2 class="text-lg font-medium leading-6 text-gray-900">
              {{ item.title }}
            </h2>
            <p class="mt-4 text-sm text-gray-500">{{ item.subtitle }}</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-gray-900"
                >Rs.{{ item.fee }}</span
              >
              {{ ' ' }}
            </p>
            <button
              class="block w-full py-2 mt-8 text-sm font-semibold text-center text-white border rounded-md border-primary bg-primary hover:bg-darkPrimary"
              @click="checkIfLoggedIn(item)"
            >
              <div>
                <div
                  v-if="
                    this.loggedInUser &&
                      this.loggedInUser.id &&
                      this.loggedInUser.orders.length &&
                      this.loggedInUser.orders.some(
                        (i) => i.courseId == item.id && i.flag === 1
                      )
                  "
                  class="flex justify-center"
                >
                  <span> Waiting For Approval</span>
                </div>
                <div
                  v-else-if="
                    this.loggedInUser &&
                      this.loggedInUser.id &&
                      this.loggedInUser.orders.length &&
                      this.loggedInUser.orders.some(
                        (i) => i.courseId == item.id && i.flag === 2
                      )
                  "
                  class="flex justify-center"
                >
                  <CheckCircleIcon class="w-5 h-5"></CheckCircleIcon>
                  <span> Purchased</span>
                </div>
                <span v-else> Buy {{ item.title }} Course</span>
              </div>
            </button>
          </div>
          <div class="px-6 pt-6 pb-8">
            <h3
              class="text-xs font-medium tracking-wide text-gray-900 uppercase"
            >
              What's included
            </h3>
            <ul class="mt-6 space-y-4">
              <li
                v-for="feature in features[item.title]"
                :key="feature"
                class="flex space-x-3"
              >
                <CheckIcon
                  class="flex-shrink-0 w-5 h-5 text-darkPrimary"
                  aria-hidden="true"
                />
                <span class="text-sm text-gray-500">{{ feature }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- login or registration  modal -->
    <modal @close="openAuthModal = false" :visibility="openAuthModal">
      <template v-slot:content>
        <auth @cancle="openAuthModal = false" />
      </template>
    </modal>
    <!-- payment details modal -->
    <modal @close="openPaymentModal = false" :visibility="openPaymentModal">
      <template v-slot:content>
        <payment-details
          @cancle="openPaymentModal = false"
          :selectedCourseId="selectedCourseId"
          :fee="fee"
        ></payment-details>
      </template>
    </modal>
  </div>
</template>

<script>
import { CheckIcon, CheckCircleIcon } from '@heroicons/vue/solid';
import { mapActions, mapState } from 'vuex';
// import AuthMiddleware from '../middleware/Auth';
import Modal from '../components/Modal.vue';
import Auth from './Auth.vue';
import PaymentDetails from './PaymentDetails.vue';
export default {
  components: {
    CheckIcon,
    CheckCircleIcon,
    Modal,
    Auth,
    PaymentDetails,
  },

  data() {
    return {
      selectedCourseId: null,
      purchasedCourseId: null,
      fee: null,
      openAuthModal: false,
      openPaymentModal: false,
      features: {
        Basic: [
          'About Stock Market',
          'Stock Selection',
          'Stock Study',
          'Fundamental Analysis',
          'Types Of Market',
          'Introduction Of Commodity And Currency',
          'Equity Trading',
          'Basic Of Chart',
        ],

        Advance: [
          'Everything Included in Basic',
          'Candlestick Chart / Bar Chart / Line Chart',
          'Future And Options (Equity/Commodities/Currency)',
          'Open Interest',
          'Option Trading',
          'Future Trading',
          'Indicators',
        ],
        Premium: [
          'Everything Included in Advance',
          'Price Action',
          'Trend Line',
          'Supports And Resistance',
          'Option Data',
          'Algo Trading',
        ],
      },
    };
  },
  mounted() {
    this.getCourses({ type: 'financial' });
    if (this.loggedInUser.id) {
      this.getUser(this.loggedInUser.id);
      this.checkOrders();
    }
  },
  computed: {
    ...mapState('course', ['courses']),
    ...mapState('auth', ['loggedInUser']),
  },
  methods: {
    ...mapActions('course', ['getCourses']),
    ...mapActions('auth', ['getUser']),

    checkIfLoggedIn(course) {
      if (this.loggedInUser) {
        this.openPaymentModal = true;
        this.selectedCourseId = course.id;
        this.fee = course.fee;
      } else {
        this.openAuthModal = true;
      }
    },
    checkOrders() {
      if (this.loggedInUser.orders.length) {
        this.purchasedCourseId = this.loggedInUser.orders[0].courseId;
      }
    },
  },
};
</script>

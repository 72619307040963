import axios from 'axios';
import router from '../router';
import snakeToCamel from '../utils/snakeToCamel';
// import Auth from './Auth';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

instance.interceptors.response.use(
  (response) => snakeToCamel(response),
  (error) => {
    if (error.response.status === 401) {
      console.log('401 error');
      // Auth.logout();
    } else if (error.response.status === 403) {
      router.push('/403');
    } else {
      return Promise.reject(snakeToCamel(error));
    }
  }
);

export default instance;

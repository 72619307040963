<template>
  <Main />
  <Notification />
</template>

<script>
import Main from './views/Main.vue';
import Notification from './components/Notification.vue';
import { mapActions } from 'vuex';
import AuthMiddleware from './middleware/Auth';

export default {
  name: 'App',
  components: {
    Main,
    Notification,
  },
  mounted() {
    const token = window.localStorage.getItem('token');
    if (token) {
      AuthMiddleware.updateAxiosToken(token);
      const user = window.localStorage.getItem('user');
      if (user) {
        this.getUser();
      }
    }
  },

  methods: {
    ...mapActions('auth', ['getUser']),
  },
};
</script>

<style>
@import './index.css';
</style>

const mutations = {
  setUsers(state, payload) {
    state.users = payload;
  },
  setOrders(state, payload) {
    state.orders = payload;
  },
  setCounts(state, payload) {
    state.counts = payload;
  },
};

export default mutations;

import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js';
import { store } from './store';
import './index.css';
import { VueClipboard } from '@soerenmartius/vue3-clipboard';

const app = createApp(App);

app
  .use(store)
  .use(router)
  .use(VueClipboard)
  .mount('#app');

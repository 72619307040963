const mutations = {
  setPaymentModes(state, data) {
    state.paymentModes = data;
  },
  setLoggedInUser(state, payload) {
    state.loggedInUser = payload;
  },
  showAdminView(state, payload) {
    state.showAdminView = payload;
  },
};

export default mutations;

import actions from './actions';
import mutations from './mutations';
const state = {
  user: {},
  paymentModes: [],
  loggedInUser: {},
  showAdminView: false,
};

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};

<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                >
                  Name
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                >
                  Course
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                >
                  Purchased Date
                </th>

                <th
                  scope="col"
                  class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                >
                  Status
                </th>

                <th
                  scope="col"
                  class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(order, index) in orders"
                :key="index"
                :class="personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td
                  class="px-6 py-4 text-sm font-medium text-gray-900 capitalize whitespace-nowrap"
                >
                  {{ order.user.name }}
                </td>
                <td class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {{ order.course.title + '(Rs.' + order.course.fee + ')' }}
                </td>
                <td class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {{ order.createdAt }}
                </td>
                <td class="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                  {{ getStatus(order.flag[0]) }}
                </td>

                <td class="px-6 py-4 text-sm font-medium whitespace-nowrap">
                  <button
                    @click="changeOrderStatus(order, 'complete')"
                    class="text-primary hover:text-indigo-900"
                  >
                    Approve
                  </button>
                  <button
                    @click="changeOrderStatus(order, 'reject')"
                    class="ml-4 text-red-600 hover:text-indigo-900"
                  >
                    Reject
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
const people = [
  {
    name: 'Jane Cooper',
    course: {
      name: 'Basic',
    },
    createdAt: '2022-10-10',
  },
  {
    name: 'Cody Fisher',
    course: {
      name: 'Primary',
    },
    createdAt: '2022-10-30',
  },
];

export default {
  setup() {
    return {
      people,
    };
  },
  computed: {
    ...mapState('admin', ['orders']),
  },
  mounted() {
    this.getOrders();
  },
  methods: {
    ...mapMutations('notification', ['setNotification']),
    ...mapActions('admin', ['getOrders', 'updateOrder']),

    getStatus(value) {
      let status = '';
      switch (value) {
        case 'FLAG_PENDING':
          status = 'Pending';
          break;
        case 'FLAG_COMPLETED':
          status = 'Completed';
          break;
        case 'FLAG_REJECTED':
          status = 'Rejected';
          break;
        default:
          return status;
      }
      return status;
    },

    changeOrderStatus(item, status) {
      item.status = status;
      this.updateOrder(item).then(() => {
        this.getOrders();
        this.setNotification({
          title: 'Order Modified',
          type: 'success',
        });
      });
    },
  },
};
</script>

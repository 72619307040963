<template>
  <div>
    <div class="relative pb-32 overflow-hidden bg-primary">
      <div
        class="absolute inset-x-0 inset-y-0 flex justify-center w-full overflow-hidden transform -translate-x-1/2 left-1/2 lg:inset-y-0"
        aria-hidden="true"
      >
        <div class="flex-grow bg-opacity-75 bg-sky-900" />

        <div class="flex-grow bg-opacity-75 bg-sky-800" />
      </div>
      <header class="relative py-10">
        <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold text-white">
            Profile
          </h1>
        </div>
      </header>
    </div>

    <main class="relative -mt-32">
      <div class="px-4 pb-6 mx-auto max-w-screen-2xl sm:px-6 lg:pb-16 lg:px-8">
        <div class="overflow-hidden bg-white rounded-lg shadow">
          <div
            class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x"
          >
            <aside class="py-6 lg:col-span-3">
              <nav class="space-y-1">
                <a
                  v-for="item in subNavigation"
                  :key="item.name"
                  @click="changeOption(item)"
                  :class="[
                    item.current
                      ? 'bg-teal-50 border-teal-500 text-teal-700 hover:bg-teal-50 hover:text-teal-700'
                      : 'border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'group border-l-4 px-3 py-2 flex items-center text-sm font-medium',
                  ]"
                  :aria-current="item.current ? 'page' : undefined"
                >
                  <component
                    :is="item.icon"
                    :class="[
                      item.current
                        ? 'text-teal-500 group-hover:text-teal-500'
                        : 'text-gray-400 group-hover:text-gray-500',
                      'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                    ]"
                    aria-hidden="true"
                  />
                  <span class="truncate">
                    {{ item.name }}
                  </span>
                </a>
              </nav>
            </aside>
            <div
              v-if="selectedOption === 'profile'"
              class="divide-y divide-gray-200 lg:col-span-9"
            >
              <!-- Profile section -->
              <div class="px-4 py-6 sm:p-6 lg:pb-8">
                <div>
                  <h2 class="text-lg font-medium leading-6 text-gray-900">
                    Profile
                  </h2>
                  <p class="mt-1 text-sm text-gray-500">
                    While registering you have added this information
                  </p>
                </div>
                <div class="flex flex-col mt-6 lg:flex-row">
                  <div class="flex-grow space-y-6">
                    <div>
                      <label
                        for="name"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Name
                      </label>
                      <div class="flex mt-1 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="name"
                          id="name"
                          autocomplete="name"
                          class="flex-grow block w-full min-w-0 border-gray-300 rounded-none focus:ring-sky-500 focus:border-sky-500 rounded-r-md sm:text-sm"
                          v-model="loggedInUser.name"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for="phone"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Phone
                      </label>
                      <div class="flex mt-1 rounded-md shadow-sm">
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          autocomplete="phone"
                          class="flex-grow block w-full min-w-0 border-gray-300 rounded-none focus:ring-sky-500 focus:border-sky-500 rounded-r-md sm:text-sm"
                          v-model="loggedInUser.phone"
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        for="referralCode"
                        class="block text-sm font-medium text-gray-700"
                      >
                        Your Referral Code
                      </label>
                      <div class="flex mt-1 rounded-md shadow-sm">
                        <div
                          class="relative flex items-stretch flex-grow focus-within:z-10"
                        >
                          <input
                            type="text"
                            disabled
                            name="referralCode"
                            id="referralCode"
                            autocomplete="referralCode"
                            class="flex-grow block w-full min-w-0 font-extrabold text-gray-700 border-gray-200 rounded-none shadow-none focus:ring-sky-500 focus:border-sky-500 rounded-r-md sm:text-sm"
                            v-model="loggedInUser.referralCode"
                          />
                        </div>
                        <button
                          class="relative inline-flex items-center px-4 py-2 -ml-px space-x-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-r-md bg-primary hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                          v-clipboard:copy="loggedInUser.referralCode"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                            />
                          </svg>

                          <span>Copy</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Privacy section -->
              <div class="pt-6 divide-y divide-gray-200">
                <div class="px-4 sm:px-6">
                  <div>
                    <h2 class="text-lg font-medium leading-6 text-gray-900">
                      Privacy Section
                    </h2>
                    <p class="mt-1 text-sm text-gray-500">
                      While purchasing financial courses you need to fill up
                      following fields.
                    </p>
                  </div>
                  <div class="flex flex-col mt-6 lg:flex-row">
                    <div class="flex-grow space-y-6">
                      <div>
                        <label
                          for="adhar"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Aadhar Number
                        </label>
                        <div class="flex mt-1 rounded-md shadow-sm">
                          <input
                            v-model="loggedInUser.aadharNumber"
                            type="text"
                            name="adhar"
                            id="adhar"
                            class="flex-grow block w-full min-w-0 border-gray-300 rounded-none focus:ring-sky-500 focus:border-sky-500 rounded-r-md sm:text-sm"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="pan"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Pan Number
                        </label>
                        <div class="flex mt-1 rounded-md shadow-sm">
                          <input
                            type="text"
                            name="pan"
                            id="pan"
                            class="flex-grow block w-full min-w-0 border-gray-300 rounded-none focus:ring-sky-500 focus:border-sky-500 rounded-r-md sm:text-sm"
                            v-model="loggedInUser.panNumber"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="bankAccount"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Bank Account Number
                        </label>
                        <div class="flex mt-1 rounded-md shadow-sm">
                          <input
                            type="text"
                            name="bankAccount"
                            id="bankAccount"
                            autocomplete="bankAccount"
                            class="flex-grow block w-full min-w-0 border-gray-300 rounded-none focus:ring-sky-500 focus:border-sky-500 rounded-r-md sm:text-sm"
                            v-model="loggedInUser.bankAccountNumber"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="ifsc"
                          class="block text-sm font-medium text-gray-700"
                        >
                          IFSC
                        </label>
                        <div class="flex mt-1 rounded-md shadow-sm">
                          <input
                            type="text"
                            name="ifsc"
                            id="ifsc"
                            autocomplete="ifsc"
                            class="flex-grow block w-full min-w-0 border-gray-300 rounded-none focus:ring-sky-500 focus:border-sky-500 rounded-r-md sm:text-sm"
                            v-model="loggedInUser.ifsc"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="nomineeName"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Nominee Name
                        </label>
                        <div class="flex mt-1 rounded-md shadow-sm">
                          <input
                            type="text"
                            name="nomineeName"
                            id="nomineeName"
                            autocomplete="nomineeName"
                            class="flex-grow block w-full min-w-0 border-gray-300 rounded-none focus:ring-sky-500 focus:border-sky-500 rounded-r-md sm:text-sm"
                            v-model="loggedInUser.nomineeName"
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          for="nomineeDob"
                          class="block text-sm font-medium text-gray-700"
                        >
                          Nominee DOB
                        </label>
                        <div class="flex mt-1 rounded-md shadow-sm">
                          <input
                            type="date"
                            name="nomineeDob"
                            id="nomineeDob"
                            autocomplete="nomineeDob"
                            class="flex-grow block w-full min-w-0 border-gray-300 rounded-none focus:ring-sky-500 focus:border-sky-500 rounded-r-md sm:text-sm"
                            v-model="loggedInUser.nomineeDob"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-start px-4 py-4 mt-4 sm:px-6">
                  <button
                    @click="updateProfile()"
                    type="button"
                    class="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
            <user-tree v-if="selectedOption === 'tree'"></user-tree>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  Menu,
  MenuButton,
  MenuItem,
  // MenuItems,
  Switch,
  SwitchDescription,
  SwitchGroup,
  SwitchLabel,
} from '@headlessui/vue';
import { SearchIcon } from '@heroicons/vue/solid';
import {
  BellIcon,
  //   CogIcon,
  // CreditCardIcon,
  // KeyIcon,
  MenuIcon,
  UserCircleIcon,
  //   ViewGridAddIcon,
  XIcon,
  HomeIcon,
  AdjustmentsIcon,
} from '@heroicons/vue/outline';
import { mapActions, mapMutations, mapState } from 'vuex';
import UserTree from './UserTree.vue';

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
    BellIcon,
    MenuIcon,
    SearchIcon,
    XIcon,
    UserTree,
  },
  data() {
    return {
      selectedOption: 'profile',
      subNavigation: [
        {
          name: 'Profile',
          code: 'profile',
          href: '#',
          icon: UserCircleIcon,
          current: true,
        },
        {
          name: 'My Tree',
          code: 'tree',
          href: '#',
          icon: AdjustmentsIcon,
          current: false,
        },
        // {
        //   name: 'Password',
        //   code: 'password',
        //   href: '#',
        //   icon: KeyIcon,
        //   current: false,
        // },
        // {
        //   name: 'Transactions',
        //   href: '#',
        //   code: 'transaction',
        //   icon: CreditCardIcon,
        //   current: false,
        // },
        {
          name: 'Go Home',
          code: 'home',
          href: '/',
          icon: HomeIcon,
          current: false,
        },
      ],
    };
  },
  computed: {
    ...mapState('auth', ['loggedInUser']),
  },
  methods: {
    ...mapActions('auth', ['updateUser']),
    ...mapMutations('notification', ['setNotification']),
    updateProfile() {
      this.updateUser(this.loggedInUser).then(() => {
        this.setNotification({
          title: 'User Updated',
          type: 'success',
        });
      });
    },
    changeOption(item) {
      this.selectedOption = item.code;
      if (item.code === 'home') {
        this.$router.push('/');
      }
      this.subNavigation = this.subNavigation.map((i) => {
        if (item.name === i.name) {
          return {
            name: i.name,
            icon: i.icon,
            current: true,
            code: i.code,
          };
        } else {
          return {
            name: i.name,
            icon: i.icon,
            current: false,
            code: i.code,
          };
        }
      });
    },
  },
};
</script>
